<template>
    <b-row>
        <!-- Company Wizard -->
        <b-col cols="12" v-if="!companyIsSetup">
            <form-wizard
                color="#7367F0"
                :title="null"
                :subtitle="null"
                :validate-on-back="true"
                layout="vertical"
                :finish-button-text="i18nT(`Finish`)"
                :back-button-text="i18nT(`Back`)"
                :next-button-text="i18nT(`Save and proceed`)"
                :index="curWizardStep"
                @on-change="stepChange"
                @on-complete="goToDashboard"
                class="wizard-vertical mb-3"
            >
                <tab-content
                    :before-change="bChangeStep"
                    :title="i18nT(`Organization information`)">
                    <validation-observer ref="step1Validations">
                        <!-- COMPANY NAME & CURRENCY -->
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Name`)"
                                    label-for="Name"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="Name"
                                            v-model="company.Label"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Currency`)"
                                    label-for="Currency"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Currency"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="company.MainCurrency"
                                            :options="currencies"
                                            :state="errors.length > 0 ? false : null"
                                            label="text"
                                            :reduce="(e) => e.value"
                                            id="Currency"
                                            :clearable="false"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- VAT & COMPANY TYPE -->
                        <b-row>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group
                                    :label="i18nT(`VAT number`)"
                                    label-for="vatNumber"
                                >
                                    <b-form-input
                                        id="vatNumber"
                                        v-model="company.Vat"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group
                                    :label="i18nT(`Main VAT rate (%)`)"
                                    label-for="vatRate"
                                >
                                    <b-form-input
                                        id="vatRate"
                                        v-model="company.MainVatPercent"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Company type`)"
                                    label-for="CompanyType"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Company type"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="company.CompanyType"
                                            :options="companyTypes"
                                            :clearable="false"
                                            :state="errors.length > 0 ? false : null"
                                            label="text"
                                            :reduce="(e) => parseInt(e.value)"
                                            id="Company type"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- INDUSTRY -->
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Industry`)"
                                    label-for="Industry"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Industry"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="company.Industry"
                                            :options="industries"
                                            :state="errors.length > 0 ? false : null"
                                            label="text"
                                            :reduce="(e) => parseInt(e.value)"
                                            :clearable="false"
                                            id="Industry"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Company size`)"
                                    label-for="CompanySize"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Company size"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="company.CompanySize"
                                            :options="companySizes"
                                            :state="errors.length > 0 ? false : null"
                                            label="text"
                                            :clearable="false"
                                            :reduce="(e) => parseInt(e.value)"
                                            id="Company size"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- Description -->
                        <b-row>
                            <b-col cols="12">
                                <b-form-group
                                    :label="i18nT(`Description`)"
                                    label-for="description"
                                >
                                    <quill-editor
                                        id="Description"
                                        v-model="company.Description"
                                        :options="snowOption"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </validation-observer>
                </tab-content>

                <tab-content :title="i18nT(`Address`)" :before-change="bChangeStep">
                    <validation-observer ref="step2Validations">
                        <b-row>
                            <b-col>
                                <b-form-group
                                    :label="i18nT(`Address`)"
                                    label-for="Address"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Address"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="Address"
                                            v-model="company.Address"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Zip code`)"
                                    label-for="Zip code"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Zip code"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="Zip code"
                                            v-model="company.Zip"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`State`)"
                                    label-for="state"
                                >
                                    <b-form-input
                                        id="state"
                                        v-model="company.State"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`City`)"
                                    label-for="City"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="City"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="City"
                                            v-model="company.City"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Country`)"
                                    label-for="Country"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Country"
                                        rules="required"
                                    >
                                        <v-select
                                            id="Country"
                                            v-model="company.Country"
                                            :options="countries"
                                            :state="errors.length > 0 ? false : null"
                                            :reduce="(e) => e.value"
                                            label="text"
                                            :clearable="false"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-form-group
                                    :label="i18nT(`Business phone`)"
                                    label-for="Business Phone"
                                >
                                    <MazPhoneNumberInput
                                        v-model="company.BusinessPhone"
                                        default-country-code="FR"
                                        :translations="{
                                          countrySelectorLabel: i18nT(`Country code`),
                                          countrySelectorError: i18nT(`Select a country`),
                                          phoneNumberLabel: i18nT(`Phone number`),
                                          example: i18nT(`Example:`),
                                        }"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-form-group
                                    :label="i18nT(`Business Email`)"
                                    label-for="Business Email"
                                >
                                    <b-form-input
                                        id="Business Email"
                                        v-model="company.BusinessEmail"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>

                <tab-content :title="i18nT(`Social links`)">
                    <validation-observer ref="step3Validations">
                        <b-col
                            cols="12"
                            md="9"
                        >
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`Website`)"
                                        label-for="website"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Website link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="website"
                                                v-model="company.Website"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`TikTok`)"
                                        label-for="TikTok"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`TikTok link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="TikTok"
                                                v-model="company.TikTok"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`Instagram`)"
                                        label-for="Instagram"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Instagram link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="Instagram"
                                                v-model="company.Instagram"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`Linkedin`)"
                                        label-for="linkedin"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Linkedin link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="linkedin"
                                                v-model="company.Linkedin"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`Twitter`)"
                                        label-for="twitter"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Twitter link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="twitter"
                                                v-model="company.Twitter"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`Facebook`)"
                                        label-for="facebook"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Facebook link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="facebook"
                                                v-model="company.Facebook"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="false">
                                <b-col>
                                    <b-form-group
                                        :label="i18nT(`Google +`)"
                                        label-for="google"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Google Plus link`)"
                                            rules="url"
                                        >
                                            <b-form-input
                                                id="google"
                                                v-model="company.GooglePlus"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                        </b-col>
                    </validation-observer>
                </tab-content>

                <tab-content :title="i18nT(`Logo & visuals`)">
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <h5>{{ i18nT(`Logo`) }}</h5>
                    </b-col>
                    <b-col
                        cols="12"
                        md="9"
                    >
                        <p>
                            {{ i18nT(`Add your company logo. Square image, suggested size`) }}
                            250x250px.
                        </p>
                        <b-img
                            v-if="preivewImage || company.LogoUrl"
                            thumbnail
                            fluid
                            :src="preivewImage || company.LogoUrl"
                            alt="Logo"
                        />
                        <div>
                            <b-form-file
                                v-model="image"
                                ref="imagePicker"
                                class="hidden"
                            />

                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                class="mt-0 mt-1 mr-1 mb-2"
                                @click="onImagePick"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add file`) }}
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-danger"
                                class="mt-0 mt-1 mb-2"
                                @click="onImageDelete"
                            >
                                <feather-icon
                                    icon="Trash2Icon"
                                    class="mr-25"
                                />
                                {{ i18nT(`Delete`) }}
                            </b-button>
                        </div>
                    </b-col>
                    <b-col
                        cols="12"
                        md="9"
                        v-if="false"

                    >
                        <h6>{{ i18nT(`Career page header image`) }}</h6>
                        <p>
                            {{ i18nT(`Add an optional header background image to illustrate your career site. Suggested size 1600x400px.`) }}
                        </p>

                        <b-img
                            v-if="preivewBackground || company.BackgroundHeaderUrl"
                            :src="preivewBackground || company.BackgroundHeaderUrl"
                            fluid
                            class="w-100"
                            :alt="i18nT(`Background Image`)"
                        />
                        <div>
                            <b-form-file
                                v-model="background"
                                ref="backgroundPicker"
                                class="hidden"
                            />
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                class="mt-0 mt-1 mr-1 mb-2"
                                @click="onBackgroundPick"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add file`) }}
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-danger"
                                class="mt-0 mt-1 mb-2"
                                @click="onBackgroundDelete"
                            >
                                <feather-icon
                                    icon="Trash2Icon"
                                    class="mr-25"
                                />
                                <span>{{ i18nT(`Delete`) }}</span>
                            </b-button>
                        </div>

                    </b-col>
                </tab-content>

                <tab-content :title="i18nT(`Ready`)">
                    <b-col
                        cols="12"
                        md="6"
                    >
                        <h3>{{ i18nT(`Thank you!`) }} {{ i18nT(`Where to now?`) }}</h3>
                    </b-col>
                    <b-col
                        cols="12"
                        md="10"
                    >
                        <p>{{ i18nT(`Your account is now ready to go! Please select the software you would like to start using.`) }}</p>
                        <ul class="navbar-nav ml-2 mt-1">
                            <li class="sk-soft-li nav-item mt-50 mb-50">
                                <a href="/ac/dashboard" class="d-inline-flex mr-1" @click.prevent="finishWizard('ac');">
                                    <div class="sk-software-box"><span>Ac</span></div>
                                </a>
                                <a href="/ac/dashboard" @click.prevent="finishWizard('ac');;">
                                    <span>{{i18nT(`Accounting and Invoicing`)}}</span>
                                    <br>
                                    {{ i18nT(`Create professional looking invoices and easily log and organize expenses and store receipts.`) }}
                                </a>
                            </li>
                            <li class="sk-soft-li nav-item mt-50 mb-50">
                                <a href="/hr/dashboard" class="d-inline-flex mr-1" @click.prevent="finishWizard('hr');;">
                                    <div class="sk-software-box"><span>Hr</span></div>
                                </a>
                                <a href="/hr/dashboard" @click.prevent="finishWizard('hr');;">
                                    <span>{{i18nT(`Employee Management`)}}</span>
                                    <br>
                                    {{ i18nT(`Store and manage employee information, documents, benefits, and incident reports. `) }}
                                </a>
                            </li>
                            <li class="sk-soft-li nav-item mt-50 mb-50">
                                <a  href="/re/dashboard" class="d-inline-flex mr-1" @click.prevent="finishWizard('re');">
                                    <div class="sk-software-box"><span>Re</span></div>
                                </a>
                                <a href="/re/dashboard" @click.prevent="finishWizard('re');">
                                    <span>{{i18nT(`Recruiting`)}}</span>
                                <br>
                                {{ i18nT(`Track candidates' progress from the initial sourcing to the final hiring stages.`) }}
                                </a>
                            </li>
                            <li class="sk-soft-li nav-item mt-50 mb-50">
                                <a href="/on/dashboard" class="d-inline-flex mr-1" @click.prevent="finishWizard('on');">
                                    <div class="sk-software-box"><span>On</span></div>
                                </a>
                                <a href="/on/dashboard" @click.prevent="finishWizard('on');">
                                    <span>{{i18nT(`Employee Onboarding`)}}</span>
                                <br>
                                {{ i18nT(`Simplify and streamline the employee onboarding process.`) }}
                                </a>
                            </li>
                            <li class="sk-soft-li nav-item mt-50 mb-50">
                                <a href="/en/dashboard" class="d-inline-flex mr-1" @click.prevent="finishWizard('en');">
                                    <div class="sk-software-box"><span>En</span></div>
                                </a>
                                <a href="/en/dashboard" @click.prevent="finishWizard('en');">
                                <span>{{i18nT(`Employee Engagement`)}}</span>
                                <br>
                                {{ i18nT(`Listen to your employees to receive valuable suggestions and feedback for improvement.`) }}
                                </a>
                            </li>
                        </ul>
                    </b-col>
                </tab-content>
            </form-wizard>
        </b-col>

        <!-- Company ALREADY CREATED -->
        <b-col cols="12" v-if="false">

            <b-row>
                <b-col
                    cols="12"
                    md="9"
                >
                    <validation-observer ref="simpleRules">
                        <b-form>
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-card
                                        no-body
                                        v-if="company"
                                    >
                                        <b-card-header>
                                            <h4 class="card-title">{{ i18nT(`Organization profile`) }}</h4>
                                        </b-card-header>
                                        <b-card-body>
                                            <b-row class="border-bottom">
                                                <!-- Field: Username -->
                                                <b-col
                                                    cols="12"
                                                    md="3"
                                                >
                                                    <h5>{{ i18nT(`Organization information`) }}</h5>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="9"
                                                >
                                                    <b-row>
                                                        <!--COMPANY NAME-->
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Name`)"
                                                                label-for="Name"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Name"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Name"
                                                                        v-model="company.Label"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>


                                                        <!--COMPANY TYPE-->
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Company type`)"
                                                                label-for="CompanyType"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Company type"
                                                                    rules="required"
                                                                >
                                                                    <v-select
                                                                        v-model="company.CompanyType"
                                                                        :options="companyTypes"
                                                                        :state="errors.length > 0 ? false : null"
                                                                        label="text"
                                                                        :reduce="(e) => parseInt(e.value)"
                                                                        :clearable="false"
                                                                        id="Company type"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>


                                                    </b-row>


                                                    <b-row>

                                                        <!--VAT ID-->
                                                        <b-col
                                                            cols="12"
                                                            md="4"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`VAT number`)"
                                                                label-for="vatNumber"
                                                            >
                                                                <b-form-input
                                                                    id="vatNumber"
                                                                    v-model="company.Vat"
                                                                />
                                                            </b-form-group>
                                                        </b-col>

                                                        <!--CURRENCY-->
                                                        <b-col
                                                            cols="12"
                                                            md="4"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Currency`)"
                                                                label-for="Currency"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Currency"
                                                                    rules="required"
                                                                >
                                                                    <v-select
                                                                        v-model="company.MainCurrency"
                                                                        :options="currencies"
                                                                        :state="errors.length > 0 ? false : null"
                                                                        label="text"
                                                                        :clearable="false"
                                                                        :reduce="(e) => e.value"
                                                                        id="Currency"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>


                                                        <!--VAT PERCENTAGE-->
                                                        <b-col
                                                            cols="12"
                                                            md="4"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Main VAT rate (%)`)"
                                                                label-for="vatNumber"
                                                            >
                                                                <b-form-input
                                                                    id="vatNumber"
                                                                    v-model="company.MainVatPercent"
                                                                />
                                                            </b-form-group>
                                                        </b-col>


                                                    </b-row>


                                                    <b-row>
                                                        <!--INDUSTRY-->
                                                        <b-col
                                                            cols="12"
                                                            md="12"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Industry`)"
                                                                label-for="Industry"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Industry"
                                                                    rules="required"
                                                                >
                                                                    <v-select
                                                                        v-model="company.Industry"
                                                                        :options="industries"
                                                                        :state="errors.length > 0 ? false : null"
                                                                        label="text"
                                                                        :reduce="(e) => parseInt(e.value)"
                                                                        id="Industry"
                                                                        :clearable="false"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>

                                                        <!--COMPANY SIZE-->
                                                        <b-col
                                                            cols="12"
                                                            md="12"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Company size`)"
                                                                label-for="CompanySize"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Company size"
                                                                    rules="required"
                                                                >
                                                                    <v-select
                                                                        v-model="company.CompanySize"
                                                                        :options="companySizes"
                                                                        :state="errors.length > 0 ? false : null"
                                                                        label="text"
                                                                        :reduce="(e) => parseInt(e.value)"
                                                                        id="Company size"
                                                                        :clearable="false"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>


                                                    <b-row>
                                                        <!--Description-->
                                                        <b-col cols="12">
                                                            <b-form-group
                                                                :label="i18nT(`Description`)"
                                                                label-for="description"

                                                            >
                                                                <quill-editor
                                                                    id="Description"
                                                                    v-model="company.Description"
                                                                    :options="snowOption"
                                                                />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>


                                            <!--CONTACT INFORMATION -->
                                            <b-row class="border-bottom pt-2">
                                                <!-- Field: Username -->
                                                <b-col
                                                    cols="12"
                                                    md="3"
                                                >
                                                    <h5>{{ i18nT(`Contact information`) }}</h5>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="9"
                                                >
                                                    <b-row>
                                                        <b-col>
                                                            <b-form-group
                                                                :label="i18nT(`Address`)"
                                                                label-for="Address"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Address"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Address"
                                                                        v-model="company.Address"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Zip code`)"
                                                                label-for="Zip code"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Zip code"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Zip code"
                                                                        v-model="company.Zip"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`State`)"
                                                                label-for="state"
                                                            >
                                                                <b-form-input
                                                                    id="state"
                                                                    v-model="company.State"
                                                                />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`City`)"
                                                                label-for="City"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="City"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="City"
                                                                        v-model="company.City"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Country`)"
                                                                label-for="Country"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Country"
                                                                    rules="required"
                                                                >
                                                                    <v-select
                                                                        id="Country"
                                                                        v-model="company.Country"
                                                                        :options="countries"
                                                                        :state="errors.length > 0 ? false : null"
                                                                        :reduce="(e) => e.value"
                                                                        label="text"
                                                                        :clearable="false"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col
                                                            cols="12"
                                                            md="12"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Business phone`)"
                                                                label-for="Business Phone"
                                                            >
                                                                <MazPhoneNumberInput
                                                                    :key="phoneLoaded"
                                                                    v-model="company.BusinessPhone"
                                                                    default-country-code="FR"
                                                                    :translations="{
                                                                      countrySelectorLabel: i18nT(`Country code`),
                                                                      countrySelectorError: i18nT(`Select a country`),
                                                                      phoneNumberLabel: i18nT(`Phone number`),
                                                                      example: i18nT(`Example:`),
                                                                    }"
                                                                />
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col
                                                            cols="12"
                                                            md="12"

                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Business Email`)"
                                                                label-for="Business Email"
                                                            >
                                                                <b-form-input
                                                                    id="Business Email"
                                                                    v-model="company.BusinessEmail"
                                                                />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>

                                            <!--BILLING INFORMATION -->
                                            <b-row class="border-bottom pt-2">
                                                <!-- Field: Username -->
                                                <b-col
                                                    cols="12"
                                                    md="3"
                                                >
                                                    <h5>{{ i18nT(`Billing information`) }}</h5>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="9"
                                                >
                                                    <b-row>
                                                        <b-col>
                                                            <b-form-group
                                                                :label="i18nT(`Address`)"
                                                                label-for="Address"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Address"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Address"
                                                                        v-model="company.BillingAddress"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Zip code`)"
                                                                label-for="Zip code"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Zip code"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Zip code"
                                                                        v-model="company.BillingZip"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`State`)"
                                                                label-for="state"
                                                            >
                                                                <b-form-input
                                                                    id="state"
                                                                    v-model="company.BillingState"
                                                                />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`City`)"
                                                                label-for="City"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="City"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="City"
                                                                        v-model="company.BillingCity"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Country`)"
                                                                label-for="Country"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Country"
                                                                    rules="required"
                                                                >
                                                                    <v-select
                                                                        id="Country"
                                                                        v-model="company.BillingCountry"
                                                                        :options="countries"
                                                                        :state="errors.length > 0 ? false : null"
                                                                        :reduce="(e) => e.value"
                                                                        label="text"
                                                                        :clearable="false"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>

                                                    <b-row>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Legal representative`)"
                                                                label-for="Legalrep"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Legal representative"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Legalrep"
                                                                        v-model="company.BillingPerson"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <b-form-group
                                                                :label="i18nT(`Billing Email`)"
                                                                label-for="Billing Email"
                                                                class="required"
                                                            >
                                                                <validation-provider
                                                                    #default="{ errors }"
                                                                    name="Billing Email"
                                                                    rules="required"
                                                                >
                                                                    <b-form-input
                                                                        id="Billing Email"
                                                                        v-model="company.BillingEmail"
                                                                        :state="errors.length > 0 ? false : null"
                                                                    />
                                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>

                                            <!-- SOCIAL LINKS -->
                                            <b-row class="border-bottom pt-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                    <h5>
                                            {{ i18nT(`Social links`) }}
                                    </h5>
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                    <div
                                    v-for="(social, index) in addedSocials"
                                        :key="`social`+ index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                        <b-row
                                        
                                            >
                                            <b-col cols="4" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Social network`)"
                                                    label-for="Social network">
                                                    
                                                        <v-select
                                                            v-model="social.network"
                                                            :options="availableSocials"
                                                            :clearable="false"
                                                            @input="disableSocial"
                                                            label="text"
                                                            :selectable="selected => selected.disabled !== true"
                                                        ></v-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                    cols="8"
                                                    md="8"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Social link`)"
                                                        label-for="Social link"
                                                        class="required"
                                                    >
                                                    <validation-provider
                                                    #default="{ errors }"
                                                    :name="getSocialName(social.network)"
                                                    rules="url"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Social link`)"
                                                            v-model="social.link"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>

                                                    </b-form-group>
                                                </b-col>
                                        </b-row>
                                        <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeSocials(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon" />
                                                    {{i18nT(`Remove`)}}
                                                </b-button>
                                            </div>
                                    </div>
                                    <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addSocials"
                                            >
                                                <feather-icon icon="PlusCircleIcon" />
                                                {{i18nT(`Add more`)}}
                                            </b-button>
                                        </div>
                                    </b-col>

                                </b-row>



                                           
                                            <b-row class="border-bottom pt-2">
                                                <!-- Field: Username -->
                                                <b-col
                                                    cols="12"
                                                    md="3"
                                                >
                                                    <h5>{{ i18nT(`Logo`) }}</h5>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="9"
                                                >
                                                    <h6>{{ i18nT(`Logo`) }}</h6>
                                                    <p>
                                                        {{ i18nT(`Add your company logo. Square image, suggested size`) }}
                                                        250x250px.
                                                    </p>
                                                    <b-img
                                                        v-if="preivewImage || company.LogoUrl"
                                                        thumbnail
                                                        fluid
                                                        :src="preivewImage || company.LogoUrl"
                                                        alt="Logo"
                                                    />
                                                    <div>
                                                        <b-form-file
                                                            v-model="image"
                                                            ref="imagePicker"
                                                            class="hidden"
                                                        />

                                                        <b-button
                                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                            variant="outline-primary"
                                                            class="mt-0 mt-1 mr-1 mb-2"
                                                            @click="onImagePick"
                                                        >
                                                            <feather-icon icon="PlusCircleIcon"/>
                                                            {{ i18nT(`Add file`) }}
                                                        </b-button>
                                                        <b-button
                                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                            variant="outline-danger"
                                                            class="mt-0 mt-1 mb-2"
                                                            @click="onImageDelete"
                                                        >
                                                            <feather-icon
                                                                icon="Trash2Icon"
                                                                class="mr-25"
                                                            />
                                                            {{ i18nT(`Delete`) }}
                                                        </b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="border-bottom pt-2">
                                                <!-- Field: Username -->
                                                <b-col
                                                    cols="12"
                                                    md="3"
                                                >
                                                    <h5>{{ i18nT(`Career site settings`) }}</h5>
                                                    <p class="mt-1">
                                                        {{ i18nT(`The following settings and information are used specifically for your public career site.`) }}
                                                    </p>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="9"
                                                >
                                                    <h6>{{ i18nT(`Career page header image`) }}</h6>
                                                    <p>
                                                        {{ i18nT(`Add an optional header background image to illustrate your career site. Suggested size 1600x400px.`) }}
                                                    </p>

                                                    <b-img
                                                        v-if="preivewBackground || company.BackgroundHeaderUrl"
                                                        :src="preivewBackground || company.BackgroundHeaderUrl"
                                                        fluid
                                                        class="w-100"
                                                        alt="Background Image"
                                                    />
                                                    <div>
                                                        <b-form-file
                                                            v-model="background"
                                                            ref="backgroundPicker"
                                                            class="hidden"
                                                        />
                                                        <b-button
                                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                            variant="outline-primary"
                                                            class="mt-0 mt-1 mr-1 mb-2"
                                                            @click="onBackgroundPick"
                                                        >
                                                            <feather-icon icon="PlusCircleIcon"/>
                                                            {{ i18nT(`Add file`) }}
                                                        </b-button>
                                                        <b-button
                                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                            variant="outline-danger"
                                                            class="mt-0 mt-1 mb-2"
                                                            @click="onBackgroundDelete"
                                                        >
                                                            <feather-icon
                                                                icon="Trash2Icon"
                                                                class="mr-25"
                                                            />
                                                            <span>{{ i18nT(`Delete`) }}</span>
                                                        </b-button>
                                                    </div>
                                                    <hr/>
                                                    <h6>{{ i18nT(`Video presentation`) }}</h6>
                                                    <p>
                                                        {{ i18nT(`Add an optional video presentation to illustrate your career site. You can copy and paste the embed code from Youtube or Vimeo.`) }}
                                                    </p>
                                                    <div class="mb-2">
                                                        <b-input-group>
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="VideoIcon"/>
                                                            </b-input-group-prepend>
                                                            <b-form-input
                                                                id="video"
                                                                v-model="company.CompanyVideo"
                                                            />
                                                        </b-input-group>
                                                    </div>
                                                    <hr/>
                                                    <h6>{{ i18nT(`Past/closed job positions`) }}</h6>
                                                    <p>
                                                        {{ i18nT(`Show past, closed job positions on your career page to inspire more confidence.`) }}
                                                    </p>
                                                    <div class="mb-2">
                                                        <b-form-checkbox
                                                            :checked="company.ShowPast"
                                                            v-model="company.ShowPast"
                                                            name="check-button"
                                                            switch
                                                            inline
                                                        >
                                                            {{ i18nT(`Show past, closed job position`) }}
                                                        </b-form-checkbox>
                                                    </div>
                                                    <hr/>
                                                    <h6>{{ i18nT(`Contact Information`) }}</h6>
                                                    <p>
                                                        {{ i18nT(`Show address and contact information on career website.`) }}
                                                    </p>
                                                    <div class="mb-2">
                                                        <b-form-checkbox
                                                            name="check-button"
                                                            switch
                                                            :checked="company.ShowContact"
                                                            v-model="company.ShowContact"
                                                        >
                                                            {{ i18nT(`Show contact information`) }}
                                                        </b-form-checkbox>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="border-bottom pt-2">
                                                <b-col cols="12" md="3">
                                                    <h5>{{ i18nT(`General terms and conditions of sale`) }}</h5>
                                                </b-col>
                                                <b-col cols="12" md="9">
                                                    <b-form-group>
                                                        <quill-editor
                                                            id="GeneralConditions"
                                                            v-model="company.GeneralConditions"
                                                            :options="snowOption"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <!-- <b-button
                                                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                                    variant="outline-danger"
                                                                    class="mt-0 mt-md-2 ml-1 mr-1"
                                                                  >
                                                                    <feather-icon
                                                                      icon="XIcon"
                                                                      class="mr-25"
                                                                    />
                                                                    <span>{{i18nT(`Delete`)}}</span>
                                                                  </b-button>
                                                                  <b-button
                                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                                    variant="outline-primary"
                                                                    class="mt-0 mt-md-2 ml-1 mr-1"
                                                                  >
                                                                    Preview
                                                                  </b-button> -->
                                                <div style="flex: 1"/>
                                                <b-button
                                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                    variant="primary"
                                                    class="mt-0 mt-md-2 ml-1 mr-1"
                                                    @click.prevent="validationForm"
                                                >
                                                    {{ i18nT(`Save`) }}
                                                </b-button>
                                            </b-row>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-col>
                <b-col
                    cols="12"
                    md="3"
                >
                    <b-card no-body>
                        <b-card-header>
                            <h4 class="card-title">{{ i18nT(`Public page link and status`) }}</h4>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="pb-1">
                                <b-col cols="12">
                                    <p>{{ i18nT(`Organization public page URL`) }}</p>
                                    <div
                                        @click="copyUrl"
                                        class="mb-2"
                                    >
                                        <b-input-group>
                                            <b-input-group-prepend is-text>
                                                <feather-icon icon="LinkIcon"/>
                                            </b-input-group-prepend>
                                                <b-form-input
                                                    :value="composeUrl(company)"
                                                    disabled
                                                >
                                                </b-form-input>
                                        </b-input-group>
                                    </div>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-row>
                                        <b-col cols="12" md="9">
                                            <b-form-checkbox
                                            name="check-button"
                                            switch
                                            :checked="company.SitePublished"
                                            v-model="company.SitePublished"
                                            :changed="onChangePublishStatus"
                                                >
                                                    {{ i18nT(`Page is public`) }}
                                                </b-form-checkbox>
                                        </b-col>
                                        <b-col cols="12" md="3" class="d-flex justify-content-md-end">
                                            <b-button
                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                variant="primary"
                                                @click="redirectToUrl">
                                                    {{ i18nT(`Visit`) }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                    
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

        </b-col>

        <!-- Company ALREADY CREATED WITH TABS -->

        <b-col cols="12" v-if="companyIsSetup">


          <validation-observer ref="simpleRules">
            <b-tabs
                vertical
                pills
                v-model="tabIndex"
                content-class="col-12 col-md-9 mt-1 mt-md-0"
                nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left"
            >

          <!-- GENERAL INFORMATION WITH TABS -->

          <b-tab
              :title="i18nT(`General information`)"
              @click="changeTab('general-info')"
              class="font-weight-bold"

          >
            <template #title>
              <feather-icon
                  icon="BriefcaseIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`General information`)}}</span>
            </template>
            <b-card no-body class>

              <!--ORGANIZATION INFORMATION -->

              <b-row class="border-bottom mr-1 ml-1">
                <!-- Field: Username -->



                <b-col
                    cols="12"
                    md="12"
                    class="mt-2"
                >
                  <h5 class="mb-2"><b>{{ i18nT(`Organization information`) }}</b></h5>
                  <b-row>
                    <!--COMPANY NAME-->
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Name`)"
                          label-for="Name"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Name"
                            rules="required"
                        >
                          <b-form-input
                              id="Name"
                              v-model="company.Label"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <!--COMPANY TYPE-->
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Company type`)"
                          label-for="CompanyType"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Company type"
                            rules="required"
                        >
                          <v-select
                              v-model="company.CompanyType"
                              :options="companyTypes"
                              :state="errors.length > 0 ? false : null"
                              label="text"
                              :reduce="(e) => parseInt(e.value)"
                              :clearable="false"
                              id="Company type"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                  </b-row>
                    <b-row>
                        <!--INDUSTRY-->
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Industry`)"
                                label-for="Industry"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Industry"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="company.Industry"
                                        :options="industries"
                                        :state="errors.length > 0 ? false : null"
                                        label="text"
                                        :reduce="(e) => parseInt(e.value)"
                                        id="Industry"
                                        :clearable="false"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!--COMPANY SIZE-->
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Company size`)"
                                label-for="CompanySize"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Company size"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="company.CompanySize"
                                        :options="companySizes"
                                        :state="errors.length > 0 ? false : null"
                                        label="text"
                                        :reduce="(e) => parseInt(e.value)"
                                        id="Company size"
                                        :clearable="false"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                  <b-row>

                    <!--VAT ID-->
                    <b-col
                        cols="12"
                        md="4"
                    >
                      <b-form-group
                          :label="i18nT(`VAT number`)"
                          label-for="vatNumber"
                      >
                        <b-form-input
                            id="vatNumber"
                            v-model="company.Vat"
                        />
                      </b-form-group>
                    </b-col>

                    <!--REGISTRATION NUMBER-->
                    <b-col
                        cols="12"
                        md="4"
                    >
                      <b-form-group
                          :label="i18nT(`Registration number`)"
                          label-for="registrationNumber"
                      >
                            <b-form-input
                                id="registrationNumber"
                                v-model="company.RegistrationNumber"
                            />
                      </b-form-group>
                    </b-col>


                    <!--BRANCH NUMBER-->
                    <b-col
                        cols="12"
                        md="4"
                    >
                      <b-form-group
                          :label="i18nT(`Branch number`)"
                          label-for="branchNumber"
                      >
                        <b-form-input
                            id="branchNumber"
                            v-model="company.BranchNumber"
                        />
                      </b-form-group>
                    </b-col>


                  </b-row>

                    <b-row>

                        <!--VAT PERCENTAGE-->
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Main VAT rate (%)`)"
                                label-for="vatNumber"
                            >
                                <b-form-input
                                    id="vatNumber"
                                    v-model="company.MainVatPercent"
                                />
                            </b-form-group>
                        </b-col>


                        <!--CURRENCY-->
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Currency`)"
                                label-for="Currency"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Currency"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="company.MainCurrency"
                                        :options="currencies"
                                        :state="errors.length > 0 ? false : null"
                                        label="text"
                                        :clearable="false"
                                        :reduce="(e) => e.value"
                                        id="Currency"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                    </b-row>



                  <b-row>
                    <!--Description-->
                    <b-col cols="12">
                      <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="description"

                      >
                        <quill-editor
                            id="Description"
                            v-model="company.Description"
                            :options="snowOption"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>


              <!--CONTACT INFORMATION -->
              <b-row class="border-bottom pt-2 ml-1 mr-1">
                <!-- Field: Username -->

                <b-col
                    cols="12"
                    md="12"
                >
                  <h5 class="pb-2"><b>{{ i18nT(`Contact information`) }}</b></h5>
                  <b-row>
                    <b-col>
                      <b-form-group
                          :label="i18nT(`Address`)"
                          label-for="Address"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                        >
                          <b-form-input
                              id="Address"
                              v-model="company.Address"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Zip code`)"
                          label-for="Zip code"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Zip code"
                            rules="required"
                        >
                          <b-form-input
                              id="Zip code"
                              v-model="company.Zip"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`State`)"
                          label-for="state"
                      >
                        <b-form-input
                            id="state"
                            v-model="company.State"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`City`)"
                          label-for="City"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required"
                        >
                          <b-form-input
                              id="City"
                              v-model="company.City"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Country`)"
                          label-for="Country"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Country"
                            rules="required"
                        >
                          <v-select
                              id="Country"
                              v-model="company.Country"
                              :options="countries"
                              :state="errors.length > 0 ? false : null"
                              :reduce="(e) => e.value"
                              label="text"
                              :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        cols="12"
                        lg="6"

                    >
                      <b-form-group
                          :label="i18nT(`Business phone`)"
                          label-for="Business Phone"
                      >
                        <MazPhoneNumberInput
                            :key="phoneLoaded"
                            v-model="company.BusinessPhone"
                            default-country-code="FR"
                            :translations="{
                                            countrySelectorLabel: i18nT(`Country code`),
                                            countrySelectorError: i18nT(`Select a country`),
                                            phoneNumberLabel: i18nT(`Phone number`),
                                            example: i18nT(`Example:`),
                                          }"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        lg="6"

                    >
                      <b-form-group
                          :label="i18nT(`Business Email`)"
                          label-for="Business Email"
                      >
                        <b-form-input
                            id="Business Email"
                            v-model="company.BusinessEmail"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!--BILLING INFORMATION -->
              <b-row class="border-bottom pt-2 ml-1 mr-1">
                <!-- Field: Username -->


                <b-col
                    cols="12"
                    md="12"
                >
                  <h5 class="pb-2"><b>{{ i18nT(`Billing information`) }}</b></h5>
                  <b-row>
                    <b-col>
                      <b-form-group
                          :label="i18nT(`Address`)"
                          label-for="Address"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                        >
                          <b-form-input
                              id="Address"
                              v-model="company.BillingAddress"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Zip code`)"
                          label-for="Zip code"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Zip code"
                            rules="required"
                        >
                          <b-form-input
                              id="Zip code"
                              v-model="company.BillingZip"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`State`)"
                          label-for="state"
                      >
                        <b-form-input
                            id="state"
                            v-model="company.BillingState"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`City`)"
                          label-for="City"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required"
                        >
                          <b-form-input
                              id="City"
                              v-model="company.BillingCity"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Country`)"
                          label-for="Country"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Country"
                            rules="required"
                        >
                          <v-select
                              id="Country"
                              v-model="company.BillingCountry"
                              :options="countries"
                              :state="errors.length > 0 ? false : null"
                              :reduce="(e) => e.value"
                              label="text"
                              :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Legal representative`)"
                          label-for="Legalrep"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Legal representative"
                            rules="required"
                        >
                          <b-form-input
                              id="Legalrep"
                              v-model="company.BillingPerson"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          :label="i18nT(`Billing Email`)"
                          label-for="Billing Email"
                          class="required"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Billing Email"
                            rules="required"
                        >
                          <b-form-input
                              id="Billing Email"
                              v-model="company.BillingEmail"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="text-right m-2">
                <b-button
                    variant="primary"
                    @click.prevent="validationForm"
                >
                  {{i18nT(`Save`)}}
                </b-button>
              </div>
            </b-card>

          </b-tab>

          <!-- SOCIAL LINKS WITH TABS -->

          <b-tab
              :title="i18nT(`Social links`)"
              @click.stop="changeTab('social-links')"
          >
            <template #title>
              <feather-icon
                  icon="GlobeIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`Social links`)}}</span>
            </template>
            <b-card no-body class="w-75">
              <b-row class="border-bottom mr-1 ml-1">

            <b-col
                cols="12"
                md="12"
            >
              <h5 class="mt-2">
                <b>
                  {{ i18nT(`Social links`) }}
                </b>
              </h5>
              <div
                  v-for="(social, index) in addedSocials"
                  :key="`social`+ index"
                  class="border-bottom pb-1 pt-1"
              >
                <b-row
                >
                  <b-col cols="12" md="6">
                    <b-form-group
                        :label="i18nT(`Social network`)"
                        label-for="Social network">

                      <v-select
                          v-model="social.network"
                          :options="availableSocials"
                          :clearable="false"
                          @input="disableSocial"
                          label="text"
                          :selectable="selected => selected.disabled !== true"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        :label="i18nT(`Social link`)"
                        label-for="Social link"
                        class="required"
                    >
                      <validation-provider
                          #default="{ errors }"
                          :name="getSocialName(social.network)"
                          rules="url"
                      >
                        <b-form-input
                            :placeholder="i18nT(`Social link`)"
                            v-model="social.link"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="text-right">
                  <b-button
                      variant="outline-danger"
                      @click="removeSocials(index)"
                  >
                    <feather-icon icon="MinusCircleIcon" />
                    {{i18nT(`Remove`)}}
                  </b-button>
                </div>
              </div>
              <div class="pt-1 mb-2">
                <b-button
                    variant="outline-primary"
                    @click="addSocials"
                >
                  <feather-icon icon="PlusCircleIcon" />
                  {{i18nT(`Add more`)}}
                </b-button>
              </div>
            </b-col>

          </b-row>
              <div class="text-right m-2">
                <b-button
                    variant="primary"
                    @click.prevent="validationForm"
                >
                  {{i18nT(`Save`)}}
                </b-button>
              </div>
            </b-card>
          </b-tab>

          <!-- LOGO WITH TABS -->

          <b-tab
              :title="i18nT(`Logo`)"
              @click="changeTab('logo')"
          >
            <template #title>
              <feather-icon
                  icon="ImageIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`Logo`)}}</span>
            </template>
            <b-card no-body class="w-50">
              <b-row class="border-bottom pt-2 mr-1 ml-1">
                <!-- Field: Username -->

<!--                  <h5 class="mt-2">{{ i18nT(`Logo`) }}</h5>-->

                <b-col
                    cols="12"
                    md="9"
                >
                  <h5 class="mb-1"><b>{{ i18nT(`Logo`) }}</b></h5>
                  <p>
                    {{ i18nT(`Add your company logo. Square image, suggested size`) }}
                    250x250px.
                  </p>
                  <b-img
                      v-if="preivewImage || company.LogoUrl"
                      thumbnail
                      fluid
                      :src="preivewImage || company.LogoUrl"
                      alt="Logo"
                  />
                  <div>
                    <b-form-file
                        v-model="image"
                        ref="imagePicker"
                        class="hidden"
                    />

                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="mt-0 mt-1 mr-1 mb-2"
                        @click="onImagePick"
                    >
                      <feather-icon icon="PlusCircleIcon"/>
                      {{ i18nT(`Add file`) }}
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-1 mb-2"
                        @click="onImageDelete"
                    >
                      <feather-icon
                          icon="Trash2Icon"
                          class="mr-25"
                      />
                      {{ i18nT(`Delete`) }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <div class="text-right m-2">
                <b-button
                    variant="primary"
                    @click.prevent="validationForm"
                >
                  {{i18nT(`Save`)}}
                </b-button>
              </div>
            </b-card>
          </b-tab>

          <!-- CAREER SITE SETTINGS WITH TABS -->

          <b-tab
              :title="i18nT(`Career site settings`)"
              @click="changeTab('career-site-settings')"
          >
            <template #title>
              <feather-icon
                  icon="ArchiveIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`Career site settings`)}}</span>
            </template>
            <b-card no-body>
              <b-row class="border-bottom pt-2 ml-1 mr-1">
                <!-- Field: Username -->
<!--                <b-col-->
<!--                    cols="12"-->
<!--                    md="2"-->
<!--                >-->

<!--                  <p class="mt-1">-->
<!--                    {{ i18nT(`The following settings and information are used specifically for your public career site.`) }}-->
<!--                  </p>-->
<!--                </b-col>-->
                <b-col
                    cols="12"
                    md="12"
                >
                  <div class="d-flex">
                    <h5><b>{{ i18nT(`Career site settings`) }}</b></h5>
                    <font-awesome-icon
                        class="text-primary ml-05"
                        :icon="['fas', 'info-circle']"
                        v-b-tooltip.hover.right="i18nT(`The following settings and information are used specifically for your public career site.`)"
                    />
                  </div>
                  <h6 class="mt-2">{{ i18nT(`Career page header image`) }}</h6>
                  <p>
                    {{ i18nT(`Add an optional header background image to illustrate your career site. Suggested size 1600x400px.`) }}
                  </p>

                  <b-img
                      v-if="preivewBackground || company.BackgroundHeaderUrl"
                      :src="preivewBackground || company.BackgroundHeaderUrl"
                      fluid
                      class="w-100"
                      alt="Background Image"
                  />
                  <div>
                    <b-form-file
                        v-model="background"
                        ref="backgroundPicker"
                        class="hidden"
                    />
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="mt-0 mt-1 mr-1 mb-2"
                        @click="onBackgroundPick"
                    >
                      <feather-icon icon="PlusCircleIcon"/>
                      {{ i18nT(`Add file`) }}
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-1 mb-2"
                        @click="onBackgroundDelete"
                    >
                      <feather-icon
                          icon="Trash2Icon"
                          class="mr-25"
                      />
                      <span>{{ i18nT(`Delete`) }}</span>
                    </b-button>
                  </div>
                  <hr/>
                  <h6>{{ i18nT(`Video presentation`) }}</h6>
                  <p>
                    {{ i18nT(`Add an optional video presentation to illustrate your career site. You can copy and paste the embed code from Youtube or Vimeo.`) }}
                  </p>
                  <div class="mb-2">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="VideoIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="video"
                          v-model="company.CompanyVideo"
                      />
                    </b-input-group>
                  </div>
                  <hr/>
                  <h6>{{ i18nT(`Past/closed job positions`) }}</h6>
                  <p>
                    {{ i18nT(`Show past, closed job positions on your career page to inspire more confidence.`) }}
                  </p>
                  <div class="mb-2">
                    <b-form-checkbox
                        :checked="company.ShowPast"
                        v-model="company.ShowPast"
                        name="check-button"
                        switch
                        inline
                    >
                      {{ i18nT(`Show past, closed job position`) }}
                    </b-form-checkbox>
                  </div>
                  <hr/>
                  <h6>{{ i18nT(`Contact Information`) }}</h6>
                  <p>
                    {{ i18nT(`Show address and contact information on career website.`) }}
                  </p>
                  <div class="mb-2">
                    <b-form-checkbox
                        name="check-button"
                        switch
                        :checked="company.ShowContact"
                        v-model="company.ShowContact"
                    >
                      {{ i18nT(`Show contact information`) }}
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <div class="text-right m-2">
                <b-button
                    variant="primary"
                    @click.prevent="validationForm"
                >
                  {{i18nT(`Save`)}}
                </b-button>
              </div>
            </b-card>
          </b-tab>

          <!-- GENERAL TERMS WITH TABS -->

          <b-tab
              :title="i18nT(`General terms and conditions of sale`)"
              @click="changeTab('general-terms')"
          >
            <template #title>
              <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`General terms and conditions of sale`)}}</span>
            </template>
            <b-card no-body>
              <b-row class="border-bottom pt-2 ml-1 mr-1">



                <b-col cols="12">
                  <div class="d-flex mb-1">
                  <h5><b>{{ i18nT(`General terms and conditions of sale`) }}</b></h5>
                    <font-awesome-icon
                        class="text-primary ml-05"
                        :icon="['fas', 'info-circle']"
                        v-b-tooltip.hover.right="i18nT(`Your terms and conditions will be displayed on your invoices and quotes`)"
                    />
<!--                  <p class="mt-1">-->
<!--                    {{ i18nT(`Your terms and conditions will be displayed on your invoices and quotes`) }}-->
<!--                  </p>-->
                  </div>
                  <b-form-group>
                    <quill-editor
                        id="GeneralConditions"
                        v-model="company.GeneralConditions"
                        :options="snowOption"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-right m-2">
                <b-button
                    variant="primary"
                    @click.prevent="validationForm"
                >
                  {{i18nT(`Save`)}}
                </b-button>
              </div>
            </b-card>
          </b-tab>

          <!-- PUBLIC PAGE WITH TABS -->

          <b-tab
              :title="i18nT(`Public page and status`)"
              @click="changeTab('public-page')"
          >
            <template #title>
              <feather-icon
                  icon="LinkIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`Public page and status`)}}</span>
            </template>
            <b-card no-body class="w-50">
              <b-row class="pb-1 ml-1 mr-1 border-bottom">
                <b-col cols="12">
                  <p class="pt-1"><b>{{ i18nT(`Organization public page URL`) }}</b></p>
                  <div
                      @click="copyUrl"
                      class="mb-2"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          :value="composeUrl(company)"
                          disabled
                      >
                      </b-form-input>
                    </b-input-group>
                  </div>
                </b-col>
                <b-col
                    cols="12"
                    md="12"
                >
                  <b-row>
                    <b-col cols="12" md="9">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          :checked="company.SitePublished"
                          v-model="company.SitePublished"
                          :changed="onChangePublishStatus"
                      >
                        {{ i18nT(`Page is public`) }}
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="3" class="d-flex justify-content-md-end">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="redirectToUrl">
                        {{ i18nT(`Visit`) }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
          </validation-observer>
      </b-col>

    </b-row>
</template>

<script>
import {
    BButton,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BTabs,
    BTab,
    BCardHeader,
    BFormCheckbox,
    // BFormTextarea,
    BCardBody,
    BImg,
    BInputGroup,
    VBTooltip,
    BInputGroupPrepend
} from 'bootstrap-vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import useAuth from '@/auth/useAuth'
//import _ from 'lodash'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {dictToSelectArray} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, url} from '@validations'
import {quillEditor} from 'vue-quill-editor'
import vSelect from 'vue-select'
import {BASE_URL} from "@core/utils/constants";
import $store from "@/store";
import _ from "lodash";

export default {
    components: {
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardHeader,
        BCardBody,
        BImg,
        BTabs,
        BTab,
        BFormCheckbox,
        BFormFile,
        ValidationProvider,
        ValidationObserver,
        BInputGroup,
        BInputGroupPrepend,
        quillEditor,
        FormWizard,
        TabContent,
        vSelect
    },
    directives: {
        Ripple,
      'b-tooltip': VBTooltip
    },
    data() {
        return {
            phoneLoaded: 'notLoaded',
            currentSoftware: useAuth.getCurrentSoftware(),
            saving: false,
            availableSocials: [
                {value: "Twitter", text: this.i18nT(`Twitter`), disabled: false},
                {value: "Website", text: this.i18nT(`Website`), disabled: false},
                {value: "Behance", text: this.i18nT(`Behance`), disabled: false},
                {value: "Linkedin", text: this.i18nT(`Linkedin`), disabled: false},
                {value: "Dribble", text: this.i18nT(`Dribble`), disabled: false},
                {value: "Github", text: this.i18nT(`Github`), disabled: false},
                {value: "Youtube", text: this.i18nT(`Youtube`), disabled: false},
                {value: "Instagram", text: this.i18nT(`Instagram`), disabled: false},
                {value: "Tiktok", text: this.i18nT(`TikTok`), disabled: false}
            ],
            tabNames: ['general-info', 'social-links', 'logo', 'career-site-settings', 'general-terms', 'public-page'],
            addedSocials: [{}],
            company: {},
            curWizardStep: 0,
            companyTypes: [],
            companySizes: [],
            currencies: [],
            countries: [],
            industries: [],
            tabIndex: 0,
            image: null,
            background: null,
            preivewImage: null,
            preivewBackground: null,
            snowOption: {
                theme: 'snow',
                placeholder: '',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'link'],
                        [{list: 'ordered'}, {list: 'bullet'}],
                        ['clean']
                    ]
                }
            }
        }
    },
    created(){
      let tab = this.$route.params.tab
      if(tab){
        this.tabIndex = this.tabNames.indexOf(tab)
      }
    },
    mounted() {
        let orgId = this.$route.params.orgId

        if (!orgId) {
            orgId = useAuth.getCurWorkspace() ? useAuth.getCurWorkspace().Id : '-1'
        }
        this.$http.get(`companies?id=${orgId}`).then(({data}) => {
            this.company = data.data
            this.company.CompanyType = this.company.CompanyType ? parseInt(this.company.CompanyType) : ''
            this.phoneLoaded = 'loaded'
            this.company.SitePublished = this.company.SitePublished === 1
            this.company.ShowContact = this.company.ShowContact === 1
            this.company.ShowPast = this.company.ShowPast === 1

            this.availableSocials.forEach(social => {
                if(this.company[social.value]) {
                    if(!this.addedSocials[0].network) {
                        this.addedSocials = []
                    }
                    this.addedSocials.push({
                        network: social,
                        link: this.company[social.value]
                    })
                    social.disabled = true
                }
            })
        })

      

        this.$http.get(`system/companyTypes`).then(({data}) => {
            this.companyTypes = dictToSelectArray(data.data)
        })

        this.$http.get(`system/industries`).then(({data}) => {
            this.industries = dictToSelectArray(data.data)
        })

        this.$http.get(`system/companySizes`).then(({data}) => {
            this.companySizes = dictToSelectArray(data.data)
        })

        this.$http.get(`system/countries`).then(({data}) => {
            this.countries = dictToSelectArray(data.data)
        })

        this.$http.get(`system/currencies`).then(({data}) => {
            this.currencies = dictToSelectArray(data.data)
        })
    },
    setup() {
        return {
            required
        }
    },
    watch: {
        image: function (val) {
            this.preivewImage = URL.createObjectURL(val)
        },
        background: function (val) {
            this.preivewBackground = URL.createObjectURL(val)
        }
    },
    computed: {
        companyIsSetup() {
            const curWorkspace = useAuth.getCurWorkspace()
            if (curWorkspace.IsCompleted !== 1 || !curWorkspace.WizzardCompleted) {
                return false
            } else {
                return true
            }
        }
    },
    methods: {
        changeTab(tab){
          this.tabIndex = this.tabNames.indexOf(tab)
          // this.$router.push({name: 'company-profile', params: {tab: tab}})
          let currentPath = this.$route.path
          if(this.$route.params.tab){
            currentPath=`/${this.currentSoftware}/company-profile`
          }
          history.pushState({}, null, currentPath + '/' + encodeURIComponent(tab));

        },
        getSocialName(network) {
            if(network && network.text) {
                return network.text
            } else {
                return ""
            }
        },
        redirectToUrl() {
            console.log('clicked')
            const url = this.composeUrl(this.company)
            window.open(url, '_blank')
        },
        addSocials() {
            this.addedSocials.push({})
        },
        disableSocial() {
            this.availableSocials.forEach(soc => soc.disabled = false)

            this.addedSocials.map(social => {
                for (let i = 0; i < this.availableSocials.length; i++) {
                    if(social.network && social.network.value && this.availableSocials[i].value === social.network.value) {
                        this.availableSocials[i].disabled = true
                    }
                } 
            })
        },
        removeSocials(index) {
            this.addedSocials.splice(index, 1)
        },
        finishWizard(softShort) {

            const curSoftware = _.values(useAuth.getSoftwares()).find(
                software => software.short === softShort
            )

            let self = this;
            let formData = new FormData();
            formData.append('id', this.company.Id) ;
            formData.append('WizzardCompleted', 1)
            formData.append('step', 5)

            this.$http.post('companies', formData).then((d) => {
              useAuth.setCurWorkspace(d.data.data)
              self.$store.commit('app/SET_CURRENT_WORKSPACE', d.data.data)

                self.$http
                    .post(BASE_URL + 'main/switchSoftware', {
                        software_id: curSoftware.id
                    })
                    .then(() => {

                       useAuth.setCurrentSoftware(softShort)

                        self.$store.commit('app/SET_CURRENT_SOFTWARE', softShort)

                          // self.$router.replace(`/${softShort}/dashboard`)

                      if(softShort === 'ac'){
                        window.location.replace('/ac/finances')
                        // self.$router.push({name:'finances'});
                      } else{
                        // self.$router.push({name:`${softShort}_dashboard`});
                        window.location.replace(`/${softShort}/dashboard`)
                      }


                        // this.$toast({
                        //     component: ToastificationContent,
                        //     position: 'top-right',
                        //     props: {
                        //         title: this.i18nT(`Workspace setup is now complete!`),
                        //         icon: 'InfoIcon',
                        //         variant: 'success'
                        //     }
                        // })
                    })

            })
        },

        goToDashboard() {
            this.finishWizard('ac')
          // this.$router.push({name: 're_dashboard'})
        },
        stepChange(from, to) {
            //console.log('From step :', from, 'To step ->', to);
            this.curWizardStep = to;
            const formData = new FormData()
            formData.append('id', this.company.Id)
            formData.append('step', from+1)
            switch (from) {
                case 0 :
                    formData.append('Label', this.company.Label)
                    if(this.company.Vat){
                        formData.append('Vat', this.company.Vat)
                        this.$store.commit('app/SET_OVERLAY_MESSAGE', this.i18nT(`Fetching company data, please wait...`));
                    }
                    formData.append('MainVatPercent', this.company.MainVatPercent)
                    formData.append('MainCurrency', this.company.MainCurrency)
                    formData.append('CompanyType', parseInt(this.company.CompanyType))
                    formData.append('Industry', this.company.Industry)
                    formData.append('CompanySize', parseInt(this.company.CompanySize))
                    formData.append('Description', this.company.Description)

                    break;

                case 1 :
                    formData.append('City', this.company.City)
                    formData.append('Address', this.company.Address)
                    formData.append('Zip', this.company.Zip)
                    formData.append('State', this.company.State)
                    formData.append('Country', this.company.Country)
                    formData.append('BusinessEmail', this.company.BusinessEmail)
                    formData.append('BusinessPhone', this.company.BusinessPhone)
                    break;

                case 2 :
                    formData.append('Website', this.company.Website)
                    formData.append('Linkedin', this.company.Linkedin)
                    formData.append('Facebook', this.company.Facebook)
                    formData.append('Instagram', this.company.Instagram)
                    formData.append('TikTok', this.company.TikTok)
                    formData.append('Twitter', this.company.Twitter)
                    break;

                case 3 :
                    if (this.image) {
                        formData.append('company_logo', this.image)
                    }

                    if (this.background) {
                        formData.append('background_header', this.background)
                    }

                    if (!this.background && !this.company.BackgroundHeaderUrl) {
                        formData.append('background_header', null)
                    }

                    break;

                case 4:
                    formData.append('WizzardCompleted', 1)
                    break;
            }

            this.saveCompanyData(formData);

        },
        bChangeStep() {
            let self = this;
            return new Promise((resolve) => {
                self.$refs['step'+(self.curWizardStep+1)+'Validations'].validateWithInfo().then(({errors, fields, isValid}) => {
                    console.log(errors, fields);
                    if (isValid) {
                        return resolve(true);
                    }else {
                        return resolve(false);
                    }
                });
            });
        },
        composeUrl(company) {
            if (company && company.Id) {
                return `${window.location.protocol}//${
                    window.location.host
                }/company/${company.Label.split(' ').join('-')}_${company.Id}`
            } else {
                return ''
            }
        },
        copyUrl() {
            navigator.clipboard.writeText(this.composeUrl(this.company))
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    icon: 'InfoIcon',
                    title: `Url copied`,
                    variant: 'primary'
                }
            })
        },
        onImagePick: function () {
            this.$refs['imagePicker'].$refs.input.click()
        },
        onBackgroundPick: function () {
            this.$refs['backgroundPicker'].$refs.input.click()
        },
        onImageDelete: function () {
            this.preivewImage = null
            this.company.LogoUrl = null

            const formData = new FormData()

            formData.append('company_id', this.company.Id)
            formData.append('image', 'Logo')
            this.$http.post('companies/removeImage', formData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Organization logo has been successfully removed`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
            })
        },
        onBackgroundDelete: function () {
            this.preivewBackground = null
            this.company.BackgroundHeaderUrl = null

            const formData = new FormData()

            formData.append('company_id', this.company.Id)
            formData.append('image', 'BackgroundHeader')
            this.$http.post('companies/removeImage', formData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Organization background has been successfully removed`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
            })
        },
        onChangePublishStatus() {
            const formData = new FormData()

            formData.append('company_id', this.company.Id)
            formData.append('publish', this.company.SitePublished)

            this.$http.post('companies/publishSite', formData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Company public status changed`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
            })
        },

        saveCompanyData(formDataObj){
            if(this.saving) return;
            this.saving = true;
            this.$http
                .post('companies', formDataObj)
                .then(d => {
                    useAuth.setCurWorkspace(d.data.data)
                    this.$store.commit('app/SET_CURRENT_WORKSPACE', d.data.data)

                    if(this.curWizardStep == 1){
                        this.company.City = d.data.data.City ? d.data.data.City : ''
                        this.company.Address = d.data.data.Address ? d.data.data.Address : ''
                        this.company.Zip = d.data.data.Zip ? d.data.data.Zip : ''
                        this.company.State = d.data.data.State ? d.data.data.State : ''
                        this.company.Country = d.data.data.Country ? d.data.data.Country : ''
                        this.company.BusinessEmail = d.data.data.BusinessEmail ? d.data.data.BusinessEmail : ''
                        this.company.BusinessPhone = d.data.data.BusinessPhone ? d.data.data.BusinessPhone : ''
                    }

                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(d),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.saving = false;
                    //this.$router.push({name: `${useAuth.getCurrentSoftware()}_dashboard`})
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                    this.saving = false;
                })
        },
        validationForm() {
            this.$refs.simpleRules
                .validateWithInfo()
                .then(({errors, fields, isValid}) => {
                    if (isValid) {
                        const formData = new FormData()

                        formData.append('id', this.company.Id)
                        formData.append('Label', this.company.Label)
                        formData.append('Vat', this.company.Vat)
                        formData.append('MainCurrency', this.company.MainCurrency)
                        formData.append('MainVatPercent', this.company.MainVatPercent)
                        formData.append('CompanyType', parseInt(this.company.CompanyType))
                        formData.append('Industry', this.company.Industry)
                        formData.append('CompanySize', parseInt(this.company.CompanySize))
                        formData.append('Description', this.company.Description)
                        formData.append('City', this.company.City)
                        formData.append('Address', this.company.Address)
                        formData.append('Zip', this.company.Zip)
                        formData.append('State', this.company.State)
                        formData.append('Country', this.company.Country)
                        formData.append('BusinessEmail', this.company.BusinessEmail)
                        formData.append('BusinessPhone', this.company.BusinessPhone)
                        formData.append('GeneralConditions', this.company.GeneralConditions)


                        formData.append('RegistrationNumber', this.company.RegistrationNumber);
                        formData.append('BranchNumber', this.company.BranchNumber);

                        formData.append('BillingCity', this.company.BillingCity)
                        formData.append('BillingAddress', this.company.BillingAddress)
                        formData.append('BillingZip', this.company.BillingZip)
                        formData.append('BillingState', this.company.BillingState)
                        formData.append('BillingCountry', this.company.BillingCountry)
                        formData.append('BillingPerson', this.company.BillingPerson)
                        formData.append('BillingEmail', this.company.BillingEmail)

                        for(let i = 0; i < this.addedSocials.length; i++) {
                            if(this.addedSocials[i] && this.addedSocials[i].network) {
                                formData.append(this.addedSocials[i].network.value, this.addedSocials[i].link)
                            }
                        }
                       
                        formData.append('CompanyVideo', this.company.CompanyVideo)
                        formData.append('ShowContact', this.company.ShowContact ? 1 : 0)
                        formData.append('ShowPast', this.company.ShowPast ? 1 : 0)
                        formData.append('SitePublished', this.company.SitePublished ? 1 : 0)
                        formData.append('WizzardCompleted', 1)


                        if (this.image) {
                            formData.append('company_logo', this.image)
                        }

                        if (this.background) {
                            formData.append('background_header', this.background)
                        }

                        if (!this.background && !this.company.BackgroundHeaderUrl) {
                            formData.append('background_header', null)
                        }

                        this.$http
                            .post('companies', formData)
                            .then(d => {
                                useAuth.setCurWorkspace(d.data.data)
                                this.$store.commit('app/SET_CURRENT_WORKSPACE', d.data.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.successMessage(d),
                                        icon: 'InfoIcon',
                                        variant: 'success'
                                    }
                                })
                                if(!this.company.Id){
                                    this.$router.push({name: `${useAuth.getCurrentSoftware()}_dashboard`})
                                }
                            })
                            .catch(err => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.errorMessage(err),
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                })
                            })
                    } else {
                        let y = 1000000
                        let prevY = 1000000
                        for (const key in errors) {
                            if (errors[key].length > 0) {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: errors[key][0],
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                })
                                const el = document.getElementById(fields[key].id)
                                prevY =
                                    el.getBoundingClientRect().top + window.pageYOffset - 150
                                if (prevY <= y) y = prevY
                            }
                        }
                        if (y < 1000000) {
                            window.scrollTo({top: y, behavior: 'smooth'})
                        }
                    }
                })
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>

<style lang="scss">

.sk-soft-li {
    transition: all 0.5s ease-out;
    display: inline-flex;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 15px 10px 12px 15px;
    border-radius: 5px;
}

.sk-soft-li a {
    color: inherit;
}

.sk-soft-li a:hover {
    color: inherit;
}

.sk-soft-li:hover {
    opacity: 0.8;
}
.sk-soft-li a span {
    font-weight: 500;
    font-size: 120%;
}

.sk-software-box {
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    border-radius: 4px;
    border: 1px solid #6e6b7b!important;
}

.sk-software-box span {
    margin: 0 auto;
}

.sk-soft-li:nth-child(1) .sk-software-box {
    background: #5a5aff;
}
.sk-soft-li:nth-child(1):hover .sk-software-box {
    box-shadow: 0 4px 18px -4px rgba(90,90,255,0.65);
}

.sk-soft-li:nth-child(2) .sk-software-box {
    background: #26a7ff;
}
.sk-soft-li:nth-child(2):hover .sk-software-box {
    box-shadow: 0 4px 18px -4px rgba(38,167,255,0.65);
}

.sk-soft-li:nth-child(3) .sk-software-box {
    background: #28c77a;
}
.sk-soft-li:nth-child(3):hover .sk-software-box {
    box-shadow: 0 4px 18px -4px rgba(40,199,122,0.65);
}

.sk-soft-li:nth-child(4) .sk-software-box {
    background: #ff9f43;
}
.sk-soft-li:nth-child(4):hover .sk-software-box {
    box-shadow: 0 4px 18px -4px rgba(255,155,67,0.65);
}

.sk-soft-li:nth-child(5) .sk-software-box {
    background: #b748ff;
}
.sk-soft-li:nth-child(5):hover .sk-software-box {
    box-shadow: 0 4px 18px -4px rgba(183,72,255,0.65);
}

.section-title {
    font-size: 11px;
}

.ql-editor {
    min-height: 200px;
}

.vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
    min-width: 255px;
}

.vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav li .stepTitle {
    white-space: initial;
    text-align: left;
}

.vertical.wizard-vertical.vue-form-wizard .wizard-card-footer {
    margin-left: calc(230px + 2.2%);
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked,
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked,
.vue-form-wizard .wizard-navigation .wizard-nav li a .number {
    min-width: 40px;
}

</style>


